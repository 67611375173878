export function getProductSizes(idColor: number, product: any): Array<string> {
  const sizesList = [''];
  let totalQuantity = 0;

  product.option_product.forEach((opt: any) => {
    if (opt.option.color[0]) {
      if (
        opt.option.color[0].id == idColor &&
        opt.option.quantity !== '0'
      ) {
        sizesList.push(opt.option.size_product[0].name);
        totalQuantity += parseInt(opt.option.quantity);
      }
    } else {
      if (opt.option.quantity !== '0') {
        sizesList.push(opt.option.size_product[0].name);
        totalQuantity += parseInt(opt.option.quantity);
      }
    }
  });

  if (totalQuantity == 0) {
    sizesList.push('Sem estoque');
  }

  return sizesList;
}

export function sendToCart(selectedColor: string, size: string, product: any, quantity?: number) {
  const color = product.color.map((color: any) => {
    const colorObject = color.attributes.find(
      (clr: any) => clr.id == selectedColor
    );
    if (colorObject) {
      return color.id;
    }
    return null;
  });

  let optionsId = -1;

  product.option_product.forEach((opt: any) => {
    if (opt.option.color[0]) {
      if (
        opt.option.size_product[0].name == size &&
        opt.option.color[0].id == selectedColor
      ) {
        optionsId = opt.id;
      }
    } else {
      if (opt.option.size_product[0].name == size) {
        optionsId = opt.id;
      }
    }
  });

  const checkPromotionalPrice =
    product.price_promotion !== 0 &&
    !isNaN(product.price_promotion) &&
    product.price_promotion !== undefined &&
    product.price_promotion !== null
      ? product.price_promotion
      : product.price;

  const productSpecifications = {
    product_id: product.id || '',
    color_id: color.find((c: any) => typeof c == 'number') || 0,
    product_option: optionsId || 0,
    quantity: quantity || 1,
    color: selectedColor,
    size: size,
    price: checkPromotionalPrice,
    weight: parseFloat(product.weight.replace(',', '.')),
    comprimento: parseFloat(product.comprimento),
    height: parseFloat(product.height),
    width: parseFloat(product.width),
  };

  return productSpecifications;
}
