
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useStore } from '@/store';
import { Icon } from '@iconify/vue';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { NOTIFY } from '@/store/type-mutations';
import { typesNotification } from '@/interfaces/INotification';

import { ElCarousel } from 'element-plus';
import { ElCarouselItem } from 'element-plus';
import { ElImage } from 'element-plus';

import { getProductSizes, sendToCart } from '@/helpers/commonFunctions';

export default defineComponent({
  name: 'ProductOverview',

  components: {
    Icon,
    ElCarousel,
    ElCarouselItem,
    ElImage,
  },

  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      load: true,
      img: [
        {
          id: 0,
          id_color: 0,
          identify: '',
          name: '',
          position: 0,
          color: [
            {
              id: 0,
              name: '',
              color: '',
            },
          ],
        },
      ],
      productImages: [
        {
          id: 0,
          id_color: 0,
          name: '',
          identify: '',
          position: 0,
          color: [
            {
              id: 0,
              name: '',
              color: '',
            },
          ],
        },
      ],
      productColors: [
        {
          id: 0,
          id_product: '',
          id_colors: 0,
          attributes: [
            {
              id: 0,
              color: '',
              name: '',
            },
          ],
        },
      ],
      quickAddMessage: 'Adicionar ao carrinho',
      quantity: 1,
      sizesOfProduct: [''],
      productAttributes: {
        width: '',
        height: '',
        Weight: '',
        gender: '',
        sku: '',
        comprimento: '',
      },
      productPrice: 0,
      productName: '',
      productDescription: '',
      selectedColor: 0,
      selectedSize: '',
      env: process.env.VUE_APP_API_URL,
      showPosition: 1,
      firebaseCart:
        process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
    };
  },

  watch: {
    product: {
      handler() {
        let colors = this.product.color.map((colors: any) =>
          colors.attributes.map((cc: any) => cc.id)
        );
        this.selectedColor = parseInt(colors.toString());
        this.ableSizes(this.selectedColor);
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.id) {
      watchEffect(() => {
        if (this.products.length != 0) {
          const product = this.products.find(
            (product) => product.id == this.id
          );

          if (product !== undefined) {
            this.product = product;
            this.productDescription = product?.description_product || '';
            this.productName = product?.name_product || '';
            this.productImages = product?.img || null;
            this.productColors = product?.color || null;
            this.productPrice = product?.price || 0;
            this.productAttributes.Weight = product?.weight || '';
            this.productAttributes.gender = product?.sex || '';
            this.productAttributes.height = product?.height || '';
            this.productAttributes.width = product?.width || '';
            this.productAttributes.sku = product?.SKU || '';
            this.productAttributes.comprimento = product?.comprimento || '';
            this.load = false;
          }
        }
      });
    }
  },

  methods: {
    ableSizes(idColor: number) {
      this.sizesOfProduct = [''];
      this.sizesOfProduct = getProductSizes(idColor, this.product);
      this.selectedSize = this.sizesOfProduct[1];
    },

    async addToCart(color: number, size: string) {
      const cartItem = sendToCart(color.toString(), size, this.product, this.quantity);

      // sending then to firebase

      if (this.login.data.id !== '') {
        const checkDoc = await getDoc(
          doc(
            db,
            this.firebaseCart + this.login.data.id,
            this.product.id + color + size
          )
        );

        if (checkDoc.exists()) {
          const getPrice = this.product.price_promotion && !isNaN(this.product.price_promotion)
            ? this.product.price_promotion : this.product.price

          let newQuantity = checkDoc.data()?.quantity + this.quantity

          const maximumQuantity = 
              parseInt(this.product.option_product.filter((opt: any) => opt.id == checkDoc.data()?.product_option)[0].option.quantity)
        if (checkDoc.data()?.quantity >= maximumQuantity) {
          newQuantity = maximumQuantity
        }


          updateDoc(doc(db, this.firebaseCart + this.login.data.id, this.product.id + color + size), {
            quantity: newQuantity,
            price: newQuantity * getPrice,
          })

          this.store.commit(NOTIFY, {
            title: this.product.name_product,
            text: `Produto adicionado ao carrinho!`,
            type: typesNotification.CART,
          });
        } else {
          setDoc(
            doc(
              db,
              this.firebaseCart + this.login.data.id,
              this.product.id + color.toString() + size
            ),
            cartItem
          );
          this.store.commit(NOTIFY, {
            title: this.product.name_product,
            text: `Produto adicionado ao carrinho!`,
            type: typesNotification.CART,
          });
        }
      } else {
        this.store.commit(NOTIFY, {
          title: 'LOGIN',
          text: `Faça login para adicionar produtos ao carrinho.`,
          type: typesNotification.CART,
        });
      }
    },
  },

  setup() {
    const store = useStore();
    let product = ref({
      id: '',
      codigo_sap: '',
      name_product: '',
      description_product: '',
      status: 0,
      color: [
        {
          id: 0,
          id_product: '',
          id_colors: 0,
          attributes: [
            {
              id: 0,
              color: '',
              name: '',
            },
          ],
        },
      ],
      img: [
        {
          id: 0,
          id_color: 0,
          name: '',
          identify: '',
          position: 0,
          color: [
            {
              id: 0,
              name: '',
              color: '',
            },
          ],
        },
      ],
      option_product: [
        {
          id: 0,
          id_option_product: '',
          option: {
              id: '',
              name: '',
              color_product: 0,
              size_product: [
                {
                  id: 0,
                  name: '',
                },
              ],
              color: [
                {
                  id: 0,
                  name: '',
                  color: '',
                },
              ],
              price_add: 0,
              quantity: '0',
            },
        },
      ],
      price: 0,
      price_promotion: 0,
      sizes: '',
      sex: '',
      weight: '',
      width: '',
      height: '',
      comprimento: '',
      SKU: '',
    });

    return {
      store,
      product,
      products: computed(() => store.state.products),
      login: computed(() => store.state.login),
    };
  },
});
