import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createVNode as _createVNode, vModelRadio as _vModelRadio, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "bg-white text-center w-72 md:w-96 h-14 p-4 absolute font-bold flex flex-wrap justify-center shadow"
}
const _hoisted_3 = ["disabled", "onClick"]
const _hoisted_4 = { class: "flex flex-row justify-between mt-2 w-72 md:w-96" }
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = ["name", "value", "onClick"]
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: "cursor-pointer",
    onMouseenter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.quickAddfunction(_ctx.checkedInputs))),
    onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleMouseLeave()))
  }, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.img, (images) => {
        return (_openBlock(), _createElementBlock("div", {
          key: images.id
        }, [
          (images.color.find((color) => color.id == _ctx.checkedInputs))
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                to: `/products/${_ctx.product.id}`,
                class: "flex w-72 md:w-96 h-1/2"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("img", {
                    src: _ctx.env + 'storage/' + images?.name,
                    class: "w-72 h-72 md:w-96 md:h-96",
                    alt: "Product Image"
                  }, null, 8, _hoisted_1), [
                    [_vShow, images.position == _ctx.position]
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createElementVNode("div", {
        class: "relative bottom-14 w-content",
        onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.sizesOfProduct.length == 1
            ? (_ctx.quickAdd = 'quickAdd')
            : (_ctx.quickAdd = 'sizes')
        )),
        onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.quickAdd = 'quickAdd'))
      }, [
        _withDirectives(_createElementVNode("div", {
          class: "bg-white text-center w-72 md:w-96 font-semibold py-4 absolute text-xl shadow",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.quickAddMessage === 'Sem estoque' ? '' : _ctx.createProductCart('')
          ))
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.quickAddMessage), 1)
        ], 512), [
          [_vShow, _ctx.quickAdd === 'quickAdd']
        ]),
        (_ctx.quickAdd === 'sizes')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sizesOfProduct.slice(1), (size) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: size,
                  disabled: size == 'Sem estoque',
                  class: _normalizeClass([
              size == 'Sem estoque'
                ? 'text-xl'
                : 'w-12 hover:bg-black hover:text-white'
            , "lg:px-2 rounded"]),
                  onClick: ($event: any) => (_ctx.createProductCart(size))
                }, _toDisplayString(size), 11, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_RouterLink, {
        to: `/products/${_ctx.product.id}`,
        class: "font-bold w-full"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.product.name_product), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.color, (colors) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex h-2",
            key: colors.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(colors.attributes, (color) => {
              return _withDirectives((_openBlock(), _createElementBlock("input", {
                key: color.id,
                type: "radio",
                name: 'product' + colors.id_product,
                style: _normalizeStyle({ backgroundColor: color.color }),
                class: "checked:border-2 checked:border-black cursor-pointer",
                value: color.id,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkedInputs) = $event)),
                onClick: ($event: any) => {
              _ctx.ableSizes(color.id);
              _ctx.quickAddfunction(color.id);
            }
              }, null, 12, _hoisted_6)), [
                [_vModelRadio, _ctx.checkedInputs]
              ])
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_RouterLink, {
      to: `/products/${_ctx.product.id}`,
      class: "flex gap-2 text-sm text-start w-72 md:w-96"
    }, {
      default: _withCtx(() => [
        (
          (_ctx.product.price_promotion !== 0 && undefined && null) &&
          !Number.isNaN(_ctx.product.price_promotion)
        )
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(_ctx.product.price_promotion)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: _normalizeClass(
          (_ctx.product.price_promotion !== 0 && undefined && null) &&
          !Number.isNaN(_ctx.product.price_promotion)
            ? 'text-xs text-center line-through'
            : ''
        )
        }, _toDisplayString(new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(_ctx.finalProductPrice)), 3)
      ]),
      _: 1
    }, 8, ["to"])
  ], 32))
}